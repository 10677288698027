import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
//import PostCard from '../components/PostCard'
import SEO from '../components/seo'

const BlogPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const posts = edges
        .filter((edge) => !!edge.node.frontmatter.date)
        .map((edge /*<PostCard key={edge.node.id} post={edge.node} />*/) => (
            <li key={edge.node.fields.slug}>
                <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                    <header>
                        <h2>
                            <Link to={edge.node.fields.slug} itemProp="url">
                                <span itemProp="headline">{edge.node.frontmatter.title}</span>
                            </Link>
                        </h2>
                        <small>{edge.node.frontmatter.date}</small>
                    </header>
                </article>
            </li>
        ))
    return (
        <Layout>
            <SEO title="Aiprentice Blog" description="What we are up to." />
            <div className="blogPages">
                <ul className="blog-post-list">{posts}</ul>
            </div>
        </Layout>
    )
}

export default BlogPage

export const pageQuery = graphql`
    query($path: String!) {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { rootPage: { eq: $path } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        rootPage
                    }
                }
            }
        }
    }
`
